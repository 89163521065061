import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image, { FluidObject } from "gatsby-image"
import classNames from "classnames"
import converter from "number-to-words"
import styles from "./podcast-grid.module.scss"
import { Link } from "@components/link/link"
import { PodcastGridDataQuery } from "graphql/types"
import { PathPrefix } from "@core/constants"
import { podcastShowsOrderHashMap } from "@features/podcasts/mappers/podcast-index-mappers"

interface PodcastGridProps {
  className?: string
}

interface PodcastGridShow {
  slug: string
  title: string
  websiteUrl: string
  image: any
}

export const PodcastGrid = ({ className }: PodcastGridProps) => {
  const data: PodcastGridDataQuery = useStaticQuery(graphql`
    query PodcastGridData {
      podcastShows: allContentfulPodcastShow {
        totalCount
        nodes {
          slug
          title
          websiteUrl
          image {
            fluid(maxWidth: 200) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `)

  const { podcastShows } = data

  const orderedShows: PodcastGridShow[] = []
  const unorderedShows: PodcastGridShow[] = []

  //Converts the total podcast count from a number to a "spelled out" string
  const podcastShowNumberToWord = converter.toWords(podcastShows.totalCount) // --> 'nine'
  //Capitalizes the first word of podcast count --> "Nine"
  const totalPodcastShows =
    podcastShowNumberToWord[0].toUpperCase() +
    podcastShowNumberToWord.substring(1)

  podcastShows.nodes.forEach((show) => {
    const orderedShowIndex =
      podcastShowsOrderHashMap[
        show.slug as keyof typeof podcastShowsOrderHashMap
      ]

    if (typeof orderedShowIndex === "number" && orderedShowIndex > -1) {
      orderedShows[orderedShowIndex] = show as PodcastGridShow

      return
    }

    unorderedShows.push(show as PodcastGridShow)
  })

  const totalShows = [...orderedShows, ...unorderedShows]

  return (
    <section className={classNames(className, styles.gridContainer)}>
      <h2 className={styles.gridHeading}>
        Ligonier’s Growing Family of Podcasts
      </h2>
      <span className={styles.gridMeta}>
        {totalPodcastShows} Podcasts. Thousands of Episodes.
      </span>
      <div className={styles.gridListContainer}>
        <ul className={styles.gridList}>
          {totalShows.map((show, i: number) => {
            const { slug, image, title, websiteUrl } = show
            const path = `${PathPrefix.Podcasts}/${slug}`

            return (
              <li key={`${slug}-${i}`}>
                <Link to={websiteUrl || path}>
                  {image?.fluid && (
                    <Image
                      fluid={image.fluid as FluidObject}
                      alt={title || slug || ""}
                    />
                  )}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}
