import React, { FunctionComponent } from "react"
import { PodcastShow } from "../../podcast-types"
import styles from "./podcast-show.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface PodcastShowContentProps {
  show: PodcastShow
}

export const PodcastShowContent: FunctionComponent<PodcastShowContentProps> = ({
  show
}) => (
  <div className={styles.showContent}>
    <h1 className={styles.showTitle}>{show.title}</h1>
    {show.description && (
      <Markdown className={styles.showDescription} content={show.description} />
    )}
  </div>
)
