import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { Avatar } from "../../../../components/avatar/avatar"
import { PodcastTeacher } from "../../podcast-types"
import styles from "./podcast-show-hosts.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface PodcastShowHostsProps {
  hosts: PodcastTeacher[]
  onFollow?(): void
}

export const PodcastShowHosts: FunctionComponent<PodcastShowHostsProps> = ({
  hosts,
  onFollow
}) =>
  hosts.length ? (
    <div className={styles.hostsWrapper}>
      <ul className={styles.hosts}>
        {hosts.map((host, index) => (
          <li className={styles.host} key={index}>
            <Avatar
              className={styles.avatar}
              name={host.name}
              image={host.image}
              size="lg"
            />
            <div className={styles.content}>
              <header className={styles.header}>
                <h4 className={styles.name}>{host.name}</h4>
                {onFollow && (
                  <button className={styles.follow} onClick={onFollow}>
                    <FormattedMessage id="podcasts-follow-teacher" />
                  </button>
                )}
              </header>
              {Boolean(host.bio) && (
                <Markdown className={styles.bio} content={host.bio} />
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : null
