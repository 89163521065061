import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "gatsby"
import { Audio } from "../../../../components/audio/audio"
import { IconLabel } from "../../../../components/icon-label/icon-label"
import { AvatarList } from "../../../../components/avatar/avatar-list"
import { ShareAndLikeButton } from "../../../../components/share-and-like-button/share-and-like-button"
import { PodcastShow, PodcastLatestEpisode } from "../../podcast-types"
import styles from "./podcast-show.module.scss"
import { Markdown } from "@components/markdown/markdown"
import { PodcastListenLinks } from "@features/podcasts/components/podcast-show-links/podcast-listen-links"
import { getEpisodeTeachers } from "@features/podcasts/mappers/podcast-show-mappers"

export interface PodcastShowHeaderProps {
  show: PodcastShow
  latestEpisode?: PodcastLatestEpisode
}

export const PodcastShowHeader: FunctionComponent<PodcastShowHeaderProps> = ({
  show,
  latestEpisode
}) => {
  const audioId = show.id
  const imageBackground = show.color
    ? { backgroundColor: show.color }
    : undefined

  const { link: teachersLink, name: teachersText } = getEpisodeTeachers({
    teachers: latestEpisode?.teachers
  })

  const listenKeys = Object.keys(show.listenLinks)

  return (
    <header className={styles.header}>
      <div className={styles.showArtwork} style={imageBackground}>
        {show.image && (
          <div className={styles.showImageWrapper}>
            <img
              className={styles.showImage}
              src={show.image}
              alt={show.title}
            />
          </div>
        )}
      </div>
      {latestEpisode && (
        <aside className={styles.latestEpisode}>
          <IconLabel className={styles.latestEpisodeLabel} variant="12-audio">
            <FormattedMessage id="podcasts-latest-episode" />
          </IconLabel>
          <Link to={latestEpisode.slug}>
            <h2 className={styles.latestEpisodeTitle}>{latestEpisode.title}</h2>
          </Link>
          {latestEpisode.description && (
            <Markdown
              className={styles.latestEpisodeDescription}
              content={latestEpisode.description}
            />
          )}
          <div className={styles.latestEpisodeMeta}>
            {Boolean(latestEpisode.teachers.length) && (
              <Link className={styles.latestEpisodeTeachers} to={teachersLink}>
                <AvatarList avatars={latestEpisode.teachers} size="sm" />
                <span className={styles.latestEpisodeTeachersText}>
                  {teachersText}
                </span>
              </Link>
            )}
            <ShareAndLikeButton
              id={audioId}
              contentType="podcastShow"
              className={styles.latestEpisodeShare}
            />
          </div>
          {Boolean(latestEpisode.audioUrl) && (
            <Audio
              title={latestEpisode.title}
              className={styles.latestEpisodeAudio}
              src={latestEpisode.audioUrl}
              audioId={audioId}
            />
          )}
        </aside>
      )}
      {!latestEpisode && !!listenKeys.length && (
        <aside className={styles.latestEpisode}>
          <PodcastListenLinks showTitle={show.title} {...show.listenLinks} />
        </aside>
      )}
    </header>
  )
}
