import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { Page } from "../../../components/page/page"
import { PageProps } from "../../../types/page"
import { mapPodcastShowQueryToView } from "../mappers/podcast-show-mappers"
import { PodcastShowView } from "../views/podcast-show/podcast-show-view"
import { PodcastShowQuery } from "../podcast-types"

export const PodcastShowTemplate: FunctionComponent<
  PageProps<PodcastShowQuery>
> = ({ data, ...pageProps }) => {
  const { metadata, ...viewProps } = mapPodcastShowQueryToView(
    data!,
    pageProps.pageContext
  )

  const podcastShowData = data?.contentfulPodcastShow

  const metadataOverrides = {
    title: podcastShowData?.metadata?.title || metadata.title,
    description: podcastShowData?.metadata?.description || metadata.description,
    openGraphImage:
      podcastShowData?.metadata?.image?.fixed?.src || metadata.openGraphImage
  }

  return (
    <Page {...pageProps} metadata={{ ...metadata, ...metadataOverrides }}>
      <PodcastShowView {...viewProps} />
    </Page>
  )
}

export default PodcastShowTemplate

export const query = graphql`
  query PodcastShow(
    $showId: String
    $latestEpisodeId: String
    $recentEpisodeIds: [String]
  ) {
    contentfulPodcastShow(id: { eq: $showId }) {
      ...PodcastShowView
    }
    contentfulPodcastEpisode(id: { eq: $latestEpisodeId }) {
      ...LatestPodcastEpisode
    }
    allContentfulPodcastEpisode(filter: { id: { in: $recentEpisodeIds } }) {
      nodes {
        ...RecentPodcastEpisode
      }
    }
  }
`
