import React, { Fragment, FunctionComponent } from "react"
import { PodcastShowHosts } from "../../components/podcast-show-hosts/podcast-show-hosts"
import { PodcastShowLinks } from "../../components/podcast-show-links/podcast-show-links"
import { PodcastRecentEpisodes } from "../../components/podcast-recent-episodes/podcast-recent-episodes"
import {
  PodcastShow,
  PodcastLatestEpisode,
  PodcastRecentEpisode
} from "../../podcast-types"
import { PodcastShowHeader } from "./podcast-show-header"
import { PodcastShowContent } from "./podcast-show-content"
import styles from "./podcast-show.module.scss"
import { PodcastGrid } from "@features/podcasts/components/podcast-grid/podcast-grid"

export interface PodcastShowViewProps {
  show: PodcastShow
  latestEpisode: PodcastLatestEpisode
  recentEpisodes: PodcastRecentEpisode[]
  showViewMore: boolean
}

export const PodcastShowView: FunctionComponent<PodcastShowViewProps> = ({
  show,
  latestEpisode,
  recentEpisodes,
  showViewMore
}) => (
  <Fragment>
    <PodcastShowHeader show={show} latestEpisode={latestEpisode} />
    <PodcastShowContent show={show} />
    <PodcastShowLinks show={show} />
    <PodcastRecentEpisodes
      showViewMore={showViewMore}
      showSlug={show.slug}
      episodes={recentEpisodes}
    />
    <PodcastShowHosts hosts={show.hosts} />
    <div className={styles.hr} />
    <PodcastGrid className={styles.podcastFamilyGrid} />
  </Fragment>
)
